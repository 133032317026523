.DatePicker {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.DatePicker.-calendarOpen  {
  overflow: visible;
}

.DatePicker::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: calc(100% + 1em);
  left: 0;
  right: 0;
  margin: 0 auto;
  border-style: solid;
  z-index: 10;
  border-width: 0 1em 1em 1em;
  border-color: transparent transparent #fff transparent;
}

.DatePicker__input {
  border: none;
  background: #fff;
  border: 1px solid #ddd;
  padding: 0.4em 0.8em;
  font-family: inherit;
  text-align: center;
  border-radius: 3px;
  outline: none;
  font-size: 12px;
}

.DatePicker__input::placeholder { color: #979797; }

.DatePicker__calendarContainer {
  position: absolute;
  top: calc(100% + 2em);
  left: 50%;
  transform: translateX(-50%);
}

.Calendar, .Calendar * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  direction: rtl;
}

.Calendar {
  --cl-color-black: #444444;
  --cl-color-disabled: #d4d4d4;
  --cl-color-error: #ff2929;
  --animation-duration: 0.4s;
  font-size: 10px;
  background: #fff;
  box-shadow: 0 1em 4em rgba(0, 0, 0, 0.07);
  border-radius: 1em;
  position: relative;
  user-select: none;
  padding-top: 3.2em;
  display: flex;
  flex-direction: column;
  width: 33em;
  z-index: 10;
  max-width: 90vw;
  min-height: 36.7em;
}

.Calendar button {
  font-family: inherit;
  background: transparent;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.Calendar__header {
  display: flex;
  color: var(--cl-color-black);
  margin-bottom: 2em;
  padding: 0 2.9em;
  align-items: center;
}

.Calendar__monthArrowWrapper {
  line-height: 0;
  font-size: 1em;
  padding: 3px;
  position: relative;
  border: none;
  z-index: 1;
  opacity: 1;
  transition: 0.2s;
}

.Calendar__monthArrowWrapper:disabled, .Calendar__monthArrowWrapper.-hidden {
  opacity: 0;
  pointer-events: none;
}

.Calendar__monthArrowWrapper.-left { transform: rotate(-90deg); }
.Calendar__monthArrowWrapper.-right { transform: rotate(90deg); }

.Calendar__monthArrowWrapper:active .Calendar__monthArrow { transform: scale(0.7); }

.Calendar__monthArrow {
  border-radius: 50%;
  transition: var(--animation-duration);
  pointer-events: none;
  background-repeat: no-repeat;
  display: block;
  width: 1.7em;
  height: 1.7em;
  background-size: 100% 100%;
}

.Calendar__monthArrowWrapper.-left .Calendar__monthArrow {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg class='nc-icon-wrapper' fill='%23000000'%3E%3Cdefs stroke='none'%3E%3C/defs%3E%3Cpath class='cls-1' d='M12 23.25V.75' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px'%3E%3C/path%3E%3Cpath class='cls-2' d='M22.5 11.25L12 .75 1.5 11.25' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.Calendar__monthArrowWrapper.-right .Calendar__monthArrow {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg class='nc-icon-wrapper' fill='%23000000'%3E%3Cdefs stroke='none'%3E%3C/defs%3E%3Cpath class='cls-1' d='M12 23.25V.75' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px'%3E%3C/path%3E%3Cpath class='cls-2' d='M22.5 11.25L12 .75 1.5 11.25' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5px' fill-rule='evenodd'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
}

.Calendar__monthYearContainer {
  flex: 1;
  position: relative;
}

.Calendar__monthYear {
  font-size: 1.6em;
  font-weight: 500;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  will-change: transform, opacity;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: var(--animation-duration);
  line-height: 1;
  display: flex;
  align-items: center;
}

.Calendar__monthYear.-hiddenNext {
  opacity: 0;
  transform: translateX(-150%);
}

.Calendar__monthYear.-hiddenPrevious {
  opacity: 0;
  transform: translateX(50%);
}

.Calendar__monthYear.-shown {
  opacity: 1;
  margin-top: auto;
  margin-bottom: auto;
  transform: translateX(-50%);
}

.Calendar__monthYear.-shownAnimated { animation: var(--animation-duration) fadeTextToCenter forwards; }

.Calendar__monthYear > * {
  padding: 0.1em 0.5em;
  border: 1px solid transparent;
  transition: var(--animation-duration);
  font-size: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(0) scale(1);
  will-change: transform;
  border-radius: 5px;
}

.Calendar__monthYear:not(.-shown) > *, .Calendar__monthYear > *.-hidden {
  cursor: default;
}

.Calendar__monthText { margin-left: -0.3em; }
.Calendar__yearText:last-child { margin-right: -0.3em; }

.Calendar__monthYear.-shown > *:hover, .Calendar__monthYear > *.-activeBackground  { background: #f5f5f5; }


.Calendar__monthText:hover { transform: translateX(0.2em); }

.Calendar__yearText:hover { transform: translateX(-0.2em); }

.Calendar__monthYear .Calendar__yearText.-hidden {
  transform: translate(-50%);
  opacity: 0;
}

.Calendar__monthYear .Calendar__monthText.-hidden {
transform: translate(50%);
  opacity: 0;
}

.Calendar__monthYear:not(.-shown) > * { pointer-events: none; }

.Calendar__monthSelectorAnimationWrapper, .Calendar__yearSelectorAnimationWrapper {
  position: absolute;
  width: 100%;
  height: 80%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Calendar__monthSelectorWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Calendar__monthSelector {
  width: 85%;
  align-content: center;
  padding-bottom: 2em;
}

.Calendar__monthSelector, .Calendar__yearSelector {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  background-color: #fff;
  transform: translateY(-150%);
  will-change: transform;
  transition: 0.6s;
  height: 100%;
}

.Calendar__yearSelectorWrapper {
  width: 100%;
  height: 100%;
  padding: 0 2em;
  padding-bottom: 2em;
}

.Calendar__yearSelectorWrapper::after, .Calendar__yearSelectorWrapper::before {
  content: "";
  width: 100%;
  height: 5em;
  position: absolute;
  left: 0;
  opacity: 0;
  transition: 0.4s;
  transition-delay: 0.2s;
}

.Calendar__yearSelectorWrapper::after {
  background-image: linear-gradient(to bottom, #fff,#fff 10%, rgba(245, 245, 245, 0));
  top: -0.1em;
}

.Calendar__yearSelectorWrapper::before {
  background-image: linear-gradient(to top, #fff,#fff 10%, rgba(245, 245, 245, 0));
  bottom: 1.5em;
}

.Calendar__yearSelectorWrapper.-faded::after,.Calendar__yearSelectorWrapper.-faded::before {
  opacity: 1;
  z-index: 3;
}

.Calendar__yearSelector {
  align-content: flex-start;
  scrollbar-width: 0;
  overflow: scroll;
  position: relative;
  width: 100%;
  padding: 5em 0;
  -ms-overflow-style: none;
}

.Calendar__yearSelector::-webkit-scrollbar {
  display: none;
}

.Calendar__yearSelectorItem {
  width: 25%;
  display: flex;
  justify-content: center;
}

.Calendar__yearSelectorItem:not(:nth-child(-n + 4)) {
  margin-top: 1.5em;
}

.Calendar__yearSelectorText {
  border: none;
  font-size: 1.4em;
  min-width: 85%;
  padding: 0.2em 0.5em;
  border-radius: 8.5px;
}

.Calendar__monthSelector.-open, .Calendar__yearSelector.-open {
  transform: translateY(0);
}

.Calendar__monthSelectorItem {
  width: calc(100% / 3);
  display: flex;
  justify-content: center;
}

.Calendar__monthSelectorItem:not(:nth-child(-n + 3)) {
  margin-top: 2em;
}

.Calendar__monthSelectorItemText {
  border: none;
  padding: 0.4em 0.4em;
  border-radius: 8.5px;
  font-size: 1.3em;
  min-width: 70%;
  transition: 0.3s;
}

.Calendar__monthSelectorItemText:not(:disabled):not(.-active):hover, .Calendar__yearSelectorText:not(:disabled):not(.-active):hover { background: #f5f5f5; }
.Calendar__monthSelectorItemText:disabled, .Calendar__yearSelectorText:disabled {
  opacity: 0.5;
  cursor: default;
}


.Calendar__monthSelectorItemText.-active, .Calendar__yearSelectorText.-active {
  background-color: var(--cl-color-primary);
  color: #fff;
}

.Calendar__weekDays {
  display: flex;
  justify-content: space-between;
  color: var(--cl-color-disabled);
  font-size: 1.2em;
  margin-bottom: 1.5em;
  padding: 0 2.6em;
  position: relative;
}

.Calendar__weekDay  {
  display: block;
  width: calc(100% / 7);
  text-align: center;
}

.Calendar__sectionWrapper {
  position: relative;
  flex: 1;
  overflow: hidden;
}

.Calendar__section {
  display: flex;
  padding: 0 3.2em;
  flex-wrap: wrap;
  position: absolute;
  color: var(--cl-color-black);
  top: 0;
  left: 0;
  width: 100%;
  will-change: transform, opacity;
  transform: translateZ(0);
  backface-visibility: hidden;
  transition: var(--animation-duration);
}

.Calendar__section.-hiddenPrevious {
  opacity: 0.5;
  transform: translateX(90%);
}

.Calendar__section.-hiddenNext {
  opacity: 0.5;
  transform: translateX(-90%);
  padding: 1px solid #ddd;
}

.Calendar__section.-shown {
  opacity: 1;
  transform: translateX(0);
}

.Calendar__section.-shownAnimated { animation: var(--animation-duration) FadeContentToCenter forwards; }

.Calendar__day {
  display: block;
  width: calc(100% / 7);
  text-align: center;
  padding: calc(0.25em - 1px) 0;
  min-height: 2.25em;
  font-size: 1.6em;
  border-radius: 50%;
  transition: 0.2s;
  border: 1px solid transparent;
  margin-bottom: 0.3em;
}

.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  background: #eaeaea;
  border-radius: 50%;
  color: var(--cl-color-black);
  border-color: transparent;
}


.Calendar__day.-selected,
.Calendar__day.-selectedStart,
.Calendar__day.-selectedEnd {
  background: var(--cl-color-primary);
  color: #fff;
}

.Calendar__day.-selectedStart {
  border-radius: 0;
  border-top-right-radius: 100em;
  border-bottom-right-radius: 100em;
}

.Calendar__day.-selectedBetween {
  background: var(--cl-color-primary-light);
  color: var(--cl-color-primary);
  border-radius: 0;
}

.Calendar__day.-selectedEnd {
  border-top-left-radius: 100em;
  border-bottom-left-radius: 100em;
}

.Calendar__day:nth-child(7n):not(.-selected):not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  color: var(--cl-color-error);
}
.Calendar__day.-disabled {
  color: var(--cl-color-disabled) !important;
  background: transparent !important;
  cursor: default !important;
}
.Calendar__day.-selected { border-radius: 50%; }
.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  font-weight: 600;
  color: var(--cl-color-black);
  color: #000;
  position: relative;
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  content: "";
  position: absolute;
  bottom: 0.2em;
  display: block;
  width: 0.6em;
  height: 1px;
  background: #000;
  left: 50%;
  opacity: 0.5;
  transform: translateX(-50%);
  transition: 0.2s;
}

.Calendar__day:nth-child(7n).-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  background: var(--cl-color-error);
}

.Calendar__day.-today:hover:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  opacity: 0;
}

.Calendar__day.-blank {
  color: transparent;
  cursor: default;
}

@keyframes fadeTextToCenter {
  to {
    opacity: 1;
    transform: translateX(-50%);
  }
}

@keyframes FadeContentToCenter {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
